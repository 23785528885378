import { PLAN_ALIAS_BUSINESS, PLAN_EXTRAS } from "./constants";
import { IPlan, PlansStore } from "./types";
import { useQuery } from "@common/utils/use-query";
import { convertToNumber } from "./utils";

export const plansStore: PlansStore = {
  useGetPlans({ enabled } = { enabled: true }) {
    const res = useQuery<IPlan[]>(
      { url: "plan" },
      {
        enabled,
        refetchInterval: 1000 * 60 * 60 * 24, // reload every 24 hours
      },
    );

    // only available plans
    res.data = res.data
      ?.filter(({ can_purchase }) => can_purchase)
      .filter(({ alias }) => String(alias) !== PLAN_ALIAS_BUSINESS);

    // populate extra content
    // not present (yet) in the API response
    res.data = res.data?.map((planObj) => {
      const { alias } = planObj;
      if (PLAN_EXTRAS[alias]) {
        return { ...planObj, ...PLAN_EXTRAS[alias] };
      }

      return planObj;
    });

    // convert to float
    res.data = res.data?.map((planObj) => ({
      ...planObj,
      per_user_price: convertToNumber(planObj.per_user_price),
      price: convertToNumber(planObj.price),
    }));

    return res;
  },

  useGetPlan(alias, { enabled } = { enabled: true }) {
    return useQuery({ url: `plan/${alias}` }, { enabled });
  },
};
