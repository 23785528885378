export const ADD_ON_BASE_TYPE = "base";
const ADD_ON_ACCOUNTS_TYPE = "accounts";
const ADD_ON_INSTALLATION_TYPE = "installations";
const ADD_ON_SERVERS_TYPE = "servers";
const ADD_ON_AIRGAPPING_TYPE = "airgapping";
export const ADD_ON_OTHER_TYPE = "other";
export const ADD_ON_USERS_TYPE = "users";
export const ADD_ON_DWH_TYPE = "dwh"; // atm: subscription only
export const ADD_ON_CONNECTIONS_TYPE = "etl-connections";

export const ADD_ON_TYPES = [
  ADD_ON_BASE_TYPE,
  ADD_ON_ACCOUNTS_TYPE,
  ADD_ON_INSTALLATION_TYPE,
  ADD_ON_SERVERS_TYPE,
  ADD_ON_AIRGAPPING_TYPE,
  ADD_ON_OTHER_TYPE,
  ADD_ON_USERS_TYPE,
  ADD_ON_DWH_TYPE,
  ADD_ON_CONNECTIONS_TYPE,
] as const;

// contract
export const CONTRACT_ADD_ON_TYPES = [
  ADD_ON_ACCOUNTS_TYPE,
  ADD_ON_INSTALLATION_TYPE,
  ADD_ON_SERVERS_TYPE,
  ADD_ON_AIRGAPPING_TYPE,
  ADD_ON_OTHER_TYPE,
] as const;
export const CONTRACT_ADD_ON_WITH_BASE_TYPES = [
  ...CONTRACT_ADD_ON_TYPES,
  ADD_ON_BASE_TYPE,
] as const;
export const CONTRACT_ADD_ON_WITH_USERS_TYPES = [
  ...CONTRACT_ADD_ON_TYPES,
  ADD_ON_USERS_TYPE,
] as const;

// subscription
export const SUBSCRIPTION_ADD_ON_TYPES = [
  ADD_ON_BASE_TYPE,
  ADD_ON_DWH_TYPE,
  ADD_ON_CONNECTIONS_TYPE,
] as const;

// tier
export const STRIPE_UP_TO_INFINITY = "inf";
