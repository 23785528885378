import { captureException } from "@sentry/nextjs";
import { IBasePlan } from "./types";

// TEMP
// Prices from the API are formatted inconsistently
// We need to convert them to float
export function convertToNumber(value: string | number): number {
  if (typeof value === "string") {
    return parseFloat(value.replace("$", "").replace(",", ""));
  }

  return value;
}

export function isMonthlyPlan({
  billing_period_months,
}: Pick<IBasePlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 1);
}

export function isAnnualPlan({
  billing_period_months,
}: Pick<IBasePlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 12);
}

export function getBillingFrequencyCopy({
  billing_period_months,
}: Pick<IBasePlan, "billing_period_months">): string {
  if (billing_period_months === 1) {
    return "Monthly Billing";
  } else if (billing_period_months === 12) {
    return "Annual Billing";
  }

  captureException(
    new Error(
      `getBillingFrequencyCopy error: unknown billing frequency of "${billing_period_months}"`,
    ),
  );
  return "Unknown Billing Frequency";
}

export function formatPlanName(name: string): string {
  return name.replace("Metabase", "").trim();
}
