import { getISODateWithOffset } from "@common/utils/date";
import { PlanType, SubPlanType } from "./types";

export const HOSTING_TYPE_CLOUD = "cloud";
export const HOSTING_TYPE_SELF_HOSTED = "self-hosted";
export const HOSTING_TYPES = [
  HOSTING_TYPE_CLOUD,
  HOSTING_TYPE_SELF_HOSTED,
] as const;

export const PLAN_STARTER = "starter";
export const PLAN_STARTER_YEARLY = "starter-yearly";
export const PLAN_STARTER_WITH_DWH = "starter-with-dwh";
export const PLAN_STARTER_WITH_DWH_YEARLY = "starter-with-dwh-yearly";

export const PLAN_PRO = "pro";
export const PLAN_PRO_CLOUD_WITH_DWH = "pro-cloud-with-dwh";
export const PLAN_PRO_CLOUD_WITH_DWH_YEARLY = "pro-cloud-with-dwh-yearly";
export const SUB_PLAN_PRO_CLOUD = "pro-cloud";
export const SUB_PLAN_PRO_CLOUD_YEARLY = "pro-cloud-yearly";
export const SUB_PLAN_PRO_SELF_HOSTED = "pro-self-hosted";
export const SUB_PLAN_PRO_SELF_HOSTED_YEARLY = "pro-self-hosted-yearly";

export const PLAN_ENTERPRISE = "enterprise";
export const PLAN_ENTERPRISE_PRICE = 15000;

export const PLANS = [
  PLAN_STARTER,
  PLAN_STARTER_WITH_DWH,
  PLAN_PRO,
  PLAN_PRO_CLOUD_WITH_DWH,
  PLAN_ENTERPRISE,
] as const;
export const ALL_PLANS = [
  PLAN_STARTER,
  PLAN_STARTER_YEARLY,
  PLAN_STARTER_WITH_DWH,
  PLAN_STARTER_WITH_DWH_YEARLY,
  PLAN_PRO_CLOUD_WITH_DWH,
  PLAN_PRO_CLOUD_WITH_DWH_YEARLY,
  SUB_PLAN_PRO_CLOUD,
  SUB_PLAN_PRO_CLOUD_YEARLY,
  SUB_PLAN_PRO_SELF_HOSTED,
  SUB_PLAN_PRO_SELF_HOSTED_YEARLY,
] as const;

export const SUB_PLANS = [
  SUB_PLAN_PRO_CLOUD,
  SUB_PLAN_PRO_SELF_HOSTED,
] as const;

// placeholder
const trialEnd = getISODateWithOffset(Date.now());

export const STARTER_PLAN: PlanType = {
  slug: PLAN_STARTER,
  name: "Metabase Cloud Starter Plan",
  billing_details_name: "Cloud Starter Plan",
  shortName: "Starter",
  description: "Perfect for small teams or individuals",
  descriptionAlt:
    "Get a managed and fully-supported cloud-hosted instance of Metabase.",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Ready to migrate from Open Source Plans",
    },
    {
      checked: false,
      description: "Basic SSO",
    },
    {
      checked: false,
      description: "Limited Embedding",
    },
    {
      checked: false,
      description: "Basic Permissions",
    },
  ],
  includesAlt: [
    {
      checked: true,
      description: "Automated upgrades and backups",
    },
    {
      checked: true,
      description: "Out-of-the-box SMTP server",
    },
    {
      checked: true,
      description: "Migrate from open source",
    },
    {
      checked: false,
      description: "Single-sign with user-group mapping via SAML, LDAP or JWT",
    },
    {
      checked: false,
      description: "Interactive Embedding: white label UI, colors, and logos",
    },
  ],
  price: 85,
  userPrice: 5,
  usersIncluded: 5,
  annualPrice: 918,
  annualUserPrice: 54,
  support: "3-day email",
  trialEnd,
  active: true,
};

export const STARTER_WITH_DWH_PLAN: PlanType = {
  slug: PLAN_STARTER_WITH_DWH,
  name: "Metabase Cloud Starter Plan with storage",
  billing_details_name: "Cloud Starter Plan with storage",
  shortName: "Starter + Storage",
  description: "Perfect for small teams or individuals",
  extra: "500k stored rows",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Ready to migrate from Open Source Plans",
    },
    {
      checked: false,
      description: "Basic SSO",
    },
    {
      checked: false,
      description: "Limited Embedding",
    },
    {
      checked: false,
      description: "Basic Permissions",
    },
    {
      checked: true,
      description: "500k stored rows",
    },
  ],
  price: 120,
  userPrice: 5,
  usersIncluded: 5,
  annualPrice: 1296,
  annualUserPrice: 54,
  support: "3-day email",
  trialEnd,
  active: true,
};

export const PRO_SUBPLAN_SELF_HOSTED: SubPlanType = {
  slug: SUB_PLAN_PRO_SELF_HOSTED,
  name: "Metabase Pro Self-Hosted",
  billing_details_name: "Pro Self-Hosted",
  shortName: "Pro Self-Hosted",
  description: "Run Metabase on your servers",
  active: true,
  selfHosted: true,
};

export const PRO_SUBPLAN_CLOUD: SubPlanType = {
  slug: SUB_PLAN_PRO_CLOUD,
  name: "Metabase Pro Cloud",
  billing_details_name: "Pro Cloud",
  shortName: "Pro Cloud",
  description: "Have us run Metabase for you",
  active: true,
  selfHosted: false,
};

export const PRO_PLAN: PlanType = {
  slug: PLAN_PRO,
  name: "Metabase Pro Plan",
  billing_details_name: "Pro Plan",
  shortName: "Pro",
  description: "For larger teams & advanced use cases",
  descriptionAlt:
    "Get extra features for embedding, managing users, and compliance.",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Single sign-on via SAML, JWT, or advanced LDAP",
    },
    {
      checked: true,
      description: "Interactive embedding",
    },
    {
      checked: true,
      description: "Whitelabeling; customize logo, colors, and more",
    },
    {
      checked: true,
      description: "Row-level permissions",
    },
    {
      checked: true,
      description: "Self-hosted or Metabase Cloud deployment",
    },
    {
      checked: true,
      description: "Ready to migrate from open source instances",
    },
    {
      checked: true,
      description: "Custom domain",
    },
  ],
  includesAlt: [
    {
      checked: true,
      description: "Automated upgrades and backups",
    },
    {
      checked: true,
      description: "Out-of-the-box SMTP server",
    },
    {
      checked: true,
      description: "Migrate from open source",
    },
    {
      checked: true,
      description:
        "Single-sign and user-group permissions mapping via SAML, LDAP or JWT",
    },
    {
      checked: true,
      description:
        "Granular row- and column-level permissions via [Data Sandboxing](Granular control over permissions that let you set strong boundaries around your data)",
    },
    {
      checked: true,
      description:
        "Granular results caching controls for faster, more responsive dashboards",
    },
    {
      checked: true,
      description:
        "Create separate staging and production environments with content and configuration version control via [Serialization](Export and import content from your Metabase)",
    },
    {
      checked: true,
      description:
        "Track the usage and performance of your Metabase with Usage [Analytics](A special collection offering total visibility into what’s going on in your instance)",
    },
    {
      checked: true,
      description:
        "Customize the appearance of your instance, including custom colors and fonts",
    },
    {
      checked: true,
      description:
        "Also includes [Interactive Embedding](The full power of Metabase in your app, matching your branding (The most awesome version of embedding)) and [Static Embedding](Basic embedding of dashboards and visualizations), without the “Powered by Metabase” badge",
    },
  ],
  price: 500,
  userPrice: 10,
  usersIncluded: 10,
  annualPrice: 5400,
  annualUserPrice: 108,
  support: "3-day email",
  trialEnd,
  active: true,
  subPlans: [PRO_SUBPLAN_SELF_HOSTED, PRO_SUBPLAN_CLOUD],
};

export const PRO_CLOUD_WITH_DWH_PLAN: PlanType = {
  slug: PLAN_PRO_CLOUD_WITH_DWH,
  name: "Metabase Pro Plan with storage",
  billing_details_name: "Pro Plan with storage",
  shortName: "Pro + Storage",
  description: "For larger teams & advanced use cases",
  extra: "1M stored rows",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Single sign-on via SAML, JWT, or advanced LDAP",
    },
    {
      checked: true,
      description: "Interactive embedding",
    },
    {
      checked: true,
      description: "Whitelabeling; customize logo, colors, and more",
    },
    {
      checked: true,
      description: "Row-level permissions",
    },
    {
      checked: true,
      description: "Self-hosted or Metabase Cloud deployment",
    },
    {
      checked: true,
      description: "Ready to migrate from open source instances",
    },
    {
      checked: true,
      description: "Custom domain",
    },
    {
      checked: true,
      description: "1M stored rows",
    },
  ],
  price: 570,
  userPrice: 10,
  usersIncluded: 10,
  annualPrice: 6156,
  annualUserPrice: 108,
  support: "3-day email",
  trialEnd,
  active: true,
};

export const ENTERPRISE_PLAN: PlanType = {
  slug: PLAN_ENTERPRISE,
  name: "Metabase Cloud Enterprise Plan",
  billing_details_name: "Cloud Enterprise Plan",
  shortName: "Enterprise",
  description: "For enterprise-scale teams with custom needs",
  includes: [],
  price: 8500,
  userPrice: -1,
  usersIncluded: -1,
  annualPrice: 91800,
  annualUserPrice: -1,
  support: "N/A",
  trialEnd,
  active: false,
};
