import { setUser } from "@sentry/nextjs";
import { ReactElement, useEffect, useState } from "react";
import { ISentryProps } from "./types";

export default function Sentry({ account }: ISentryProps): ReactElement {
  // add user only once
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (!added && account) {
      setUser(account);
      setAdded(true);
    }
  }, [added, account]);

  return <></>;
}
