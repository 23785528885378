import { ReactElement } from "react";
import { Card, Flex } from "@mantine/core";
import { IWarningCardProps } from "./types";
import { useStyles } from "./WarningCard.styled";

// data-testid
export default function WarningCard({
  "data-testid": dataTestId,
  align = "flex-start",
  direction = "column",
  gap = "xs",
  justify = "flex-start",
  wrap = "wrap",
  children,
  ...cardProps
}: IWarningCardProps): ReactElement {
  const { classes } = useStyles();

  return (
    <Card
      data-testid={dataTestId}
      className={classes.root}
      bg="yellow.1"
      w="100%"
      {...cardProps}
    >
      <Flex
        align={align}
        direction={direction}
        gap={gap}
        justify={justify}
        wrap={wrap}
        w="100%"
      >
        {children}
      </Flex>
    </Card>
  );
}
