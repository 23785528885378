import { Flex, NativeSelect } from "@mantine/core";
import { ReactElement } from "react";
import { ISelectCountryProps } from "./types";
import { normaliseValue } from "./utils";
import { useConfig } from "@stores/config/hooks";

export default function SelectCountry({
  defaultValue,
  value,
  onChange,
  "data-testid": dataTestid,
  mt,
}: ISelectCountryProps): ReactElement {
  const config = useConfig();

  // countries list
  const data = Object.entries(config.permitted_countries)
    .map(([key, value]) => ({
      value: key,
      label: value,
    }))
    .sort((pair1, pair2) => pair1.label.localeCompare(pair2.label));

  // Normalise values
  const theValue = normaliseValue(data, value);
  const theDefaultValue = normaliseValue(data, defaultValue);

  return (
    <Flex
      align="flex-end"
      direction="row"
      justify="flex-start"
      wrap="nowrap"
      mt={mt === 0 ? mt : mt || "lg"}
      w="100%"
    >
      <NativeSelect
        data-testid={dataTestid}
        label="Country"
        data={data}
        defaultValue={theDefaultValue}
        value={theValue}
        onChange={onChange}
        required
        w="100%"
      />
    </Flex>
  );
}
