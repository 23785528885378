import { AddonsProvider } from "@stores/addons/context";
import { PlansProvider } from "@stores/plans/context";
import { AccountProvider } from "@stores/session/context";
import { PropsWithChildren, ReactElement } from "react";
import { SentryContainer } from "./AccountContainer.components";

export default function AccountContainer({
  children,
}: PropsWithChildren): ReactElement {
  return (
    <AccountProvider>
      <PlansProvider>
        <AddonsProvider>
          {children}

          <SentryContainer />
        </AddonsProvider>
      </PlansProvider>
    </AccountProvider>
  );
}
