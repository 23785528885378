import { useQuery } from "@common/utils/use-query";
import { AddOnsStore } from "./types";

export const addonsStore: AddOnsStore = {
  useGetAddOns() {
    return useQuery(
      { url: "/addons" },
      {
        refetchInterval: 1000 * 60 * 60 * 24, // reload every 24 hours
      },
    );
  },
};
