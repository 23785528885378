import { createContext, PropsWithChildren, ReactElement } from "react";
import { IAddon } from "./types";
import { addonsStore } from "./store";

export const AddonsContext = createContext<IAddon[]>([]);

export function AddonsProvider({ children }: PropsWithChildren): ReactElement {
  const { isLoading, error, data: addons } = addonsStore.useGetAddOns();
  if (isLoading || error || !addons) {
    return <></>;
  }

  return (
    <AddonsContext.Provider value={addons}>{children}</AddonsContext.Provider>
  );
}
