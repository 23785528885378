import { createContext, PropsWithChildren, ReactElement } from "react";
import { plansStore } from "./store";
import { IPlansContext } from "./types";
import { useAccount } from "@stores/session/hooks";

export const PlansContext = createContext<IPlansContext>({ plans: [] });

export function PlansProvider({ children }: PropsWithChildren): ReactElement {
  const account = useAccount();

  const { error, data: plans } = plansStore.useGetPlans({
    enabled: !!account,
  });

  if (error && account) {
    return <></>;
  }

  return (
    <PlansContext.Provider value={{ plans }}>{children}</PlansContext.Provider>
  );
}
