import { HEALTH_HEALTHY, STATUS_RUNNING } from "@stores/instances/constants";
import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLATION_PENDING,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_TRIAL,
} from "@stores/subscriptions/constants";
import {
  CreateInstanceDataType,
  IInstanceSetupData,
  IPurchase,
  IPurchaseAddOn,
  IPurchaseTrialUp,
} from "./types";
import {
  HOSTING_FEATURE_CUSTOM_DOMAIN,
  HOSTING_FEATURE_TRIAL_UP,
} from "./constants";
import { getPlanAlias, isDWHPlan, isProPlan } from "@common/config/plan/utils";
import { captureException } from "@sentry/nextjs";
import { ADDON_PRODUCT_DWH_TYPE } from "@stores/addons/constants";
import { AllPlanSlugType } from "@common/config/plan/types";
import { ADD_ON_DWH_TYPE } from "../../../../internal-tools/src/stores/add-ons/constants";
import { formatQuantity } from "@common/utils/string";

export function getCreateInstanceAddOnsData({
  isDWH,
  plan_alias,
}: Pick<IInstanceSetupData, "isDWH"> & {
  plan_alias: AllPlanSlugType;
}): IPurchaseAddOn[] | undefined {
  // Catering for DWH only for now
  return isDWH
    ? [
        {
          product_type: ADDON_PRODUCT_DWH_TYPE,
          prepaid_units: isProPlan(plan_alias) ? 1000000 : 500000, // Will be coming from the UI
        },
      ]
    : undefined;
}

export function getCreateInstanceData({
  isDWH = false,
  planObj,
  isSelfHosted = false,
  isAnnualBilling = false,
  dnsAlias: dns_alias,
  region,
  migrationId: migration_id,
}: IInstanceSetupData): CreateInstanceDataType | null {
  if (planObj) {
    const plan_alias = getPlanAlias({
      planSlug: planObj.slug,
      isSelfHosted,
      isAnnualBilling,
    });

    return {
      plan_alias,

      // Cloud only
      dns_alias,
      region,
      add_ons: getCreateInstanceAddOnsData({ isDWH, plan_alias }),

      // For migration
      migration_id,
    };
  } else {
    captureException(new Error("getCreateInstanceData: planObj is undefined"));
  }

  return null;
}

export function getPrettyPlanName(purchase: IPurchase): string {
  const isDWHStr = hasDWH(purchase) ? " + Storage" : "";
  return `${purchase.product.plan.billing_details_name}${isDWHStr}`;
}

interface IGetPrettyAddonRes {
  title: string;
  quantity: string;
}

export function getPrettyAddon({
  product_type,
  prepaid_units,
}: IPurchaseAddOn): IGetPrettyAddonRes {
  if (product_type === ADD_ON_DWH_TYPE) {
    return {
      title: "Storage",
      quantity: `${formatQuantity(prepaid_units)} stored rows`,
    };
  }

  return { title: product_type, quantity: `${prepaid_units}` };
}

export function isCanceledOrDeleted({
  subscription,
  hosted_instance,
}: Pick<IPurchase, "subscription" | "hosted_instance">): boolean {
  // Not using subscription.is_canceled because that includes cancellation pending
  return (
    subscription.status === SUBSCRIPTION_STATUS_CANCELLED ||
    hosted_instance?.is_deleted === true
  );
}

export function isBlocked({
  subscription,
  hosted_instance,
}: Pick<IPurchase, "subscription" | "hosted_instance">): boolean {
  return (
    isCanceledOrDeleted({
      subscription,
      hosted_instance,
    }) || hosted_instance?.health !== HEALTH_HEALTHY
  );
}

// Sort by:
//  - status: trial/active, followed by cancellation-pending, followed by cancelled
//  - within each status, sort by purchase date with recent purchase at the top
export function comparePurchase(
  purchase1: Pick<IPurchase, "subscription" | "hosted_instance">,
  purchase2: Pick<IPurchase, "subscription" | "hosted_instance">,
): number {
  return (
    statusScore(purchase2) - statusScore(purchase1) ||
    purchase2.subscription.created_at.localeCompare(
      purchase1.subscription.created_at,
    )
  );
}

function statusScore({
  subscription,
  hosted_instance,
}: Pick<IPurchase, "subscription" | "hosted_instance">): number {
  if (
    isCanceledOrDeleted({
      subscription,
      hosted_instance,
    })
  ) {
    return 0;
  } else if (subscription.status === SUBSCRIPTION_STATUS_CANCELLATION_PENDING) {
    return 1;
  } else {
    return 2;
  }
}

// changing plan
// - purchase is not canceled
// - instance is `running` OR self-hosted
// - subscription is `active` OR in `trial`
export function canChangePlan({
  hosted_instance,
  subscription,
}: Pick<IPurchase, "subscription" | "hosted_instance">): boolean {
  return (
    !isCanceledOrDeleted({ hosted_instance, subscription }) &&
    !!(
      (!hosted_instance || hosted_instance.status === STATUS_RUNNING) &&
      (subscription.status === SUBSCRIPTION_STATUS_ACTIVE ||
        subscription.status === SUBSCRIPTION_STATUS_TRIAL)
    )
  );
}

// DWH
export function hasDWH({
  subscription,
  product,
}: Pick<IPurchase, "subscription" | "product">): boolean {
  const { subscribed_products } = subscription;
  const { plan } = product;
  return (
    !!subscribed_products.find(
      ({ product_type }) => product_type === ADDON_PRODUCT_DWH_TYPE,
    ) ||
    // LEGACY
    // Since the addons implementation,
    // the plan alias does not contain the DWH feature
    isDWHPlan(plan.alias)
  );
}

function hasHostingFeature(
  { hosting_features }: Pick<IPurchase, "hosting_features">,
  hostingFeature: string,
): boolean {
  if (hosting_features) {
    return !!(Object.keys(hosting_features).indexOf(hostingFeature) > -1);
  }

  return false;
}

// We allow custom domain only if present in the hosting features list
export function canHaveACustomDomain(
  purchase: Pick<IPurchase, "hosting_features">,
): boolean {
  return hasHostingFeature(purchase, HOSTING_FEATURE_CUSTOM_DOMAIN);
}

// Can create new instance?
export function canAccountCreateNewInstance(
  purchases: Pick<IPurchase, "subscription">[] | null,
): boolean {
  const purchasesInTrial = purchases?.filter(
    (purchase) => purchase.subscription.in_trial,
  );
  return !purchasesInTrial || (purchasesInTrial && purchasesInTrial.length < 2);
}

// Is purchase eligible for pro trial?
export function canTryProPlan(
  purchase: Pick<IPurchase, "hosting_features" | "subscription">,
): boolean {
  return (
    hasHostingFeature(purchase, HOSTING_FEATURE_TRIAL_UP) &&
    purchase.subscription.status === SUBSCRIPTION_STATUS_ACTIVE
  );
}

export function getProTrial({
  trial_ups,
}: Pick<IPurchase, "trial_ups">): IPurchaseTrialUp | undefined {
  return trial_ups?.find(({ status }) => !!status);
}
