import { PLAN_STARTER } from "./constants";
import { AllPlanSlugType, IGetPlanAliasProps, PlanSlugType } from "./types";

export function getRawPlanAlias(planAlias: string): PlanSlugType {
  return (
    planAlias
      .replace("-cloud", "")
      .replace("-self-hosted", "")
      .replace("-yearly", "")

      // LEGACY
      // starter-v2 => starter
      .replace("starter-v2", "starter")
      // startup => starter
      .replace("startup", "starter")

      // TEMP
      .replace("-with-dwh", "") as PlanSlugType
  );
}

export function isProPlan(slug: string): boolean {
  return (
    slug.includes("pro-") ||
    // TO BE REMOVED
    // This will be removed once we fully use the new plans API
    slug === "pro"
  );
}

export function isEnterprisePlan(slug: string): boolean {
  return slug.includes("enterprise-");
}

export function isDWHPlan(slug: string): boolean {
  return slug.includes("-with-dwh");
}

export function isSelfHostedPlan(slug: string): boolean {
  return !(slug.includes("-cloud") || slug.includes(PLAN_STARTER));
}

export function isYearlyPlan(slug: string): boolean {
  return slug.includes("-yearly");
}

export function getPlanAlias({
  planSlug,
  isSelfHosted,
  isAnnualBilling,
}: IGetPlanAliasProps): AllPlanSlugType {
  const deploymentStr = isSelfHosted ? "-self-hosted" : "-cloud";
  const billingStr = isAnnualBilling ? "-yearly" : "";

  // starter OR pro
  const rawPlanSlug = getRawPlanAlias(planSlug);

  // plan options
  const planSuffix = planSlug.includes(PLAN_STARTER)
    ? `${billingStr}`
    : `${deploymentStr}${billingStr}`;

  return `${rawPlanSlug}${planSuffix}` as AllPlanSlugType;
}
